<!--
 * @Description: 用户头像相关操作
 * @Author: luocheng
 * @Date: 2022-03-28 15:39:15
 * @LastEditors: luocheng
 * @LastEditTime: 2022-03-29 15:11:57
-->
<template>
	<div class="user-actions">
		<!-- 自定义实现 -->
		<section class="info-desc-box" :class="{
      'active': showActions
    }" @click="onToggleShow">
      <i class="iconfont iconyonghuzhanghu userinfo"></i>
		</section>
		<!-- 内容 -->
    <!-- 283px -->
		<article class="action-menu" :style="{
        height: showActions ? '243px' : '0px'
    }">
      <!-- 头像 -->
      <section class="base" v-if="userInfo">
        <img :src="userInfo.avatar || defaultAvatar" alt="" class="avatar">
        <p class="username">{{ userInfo.name || '' }}</p>
      </section>
      <ul class="actions">
        <section class="action-section">
          <li class="action-item" @click="onClearCache">
            <i class="iconfont iconqingsaoqingchu"></i>
            <span>清除缓存</span>
          </li>
          <!-- <li class="action-item download">
            <i class="iconfont iconshoucewendang"></i>
            <span>下载操作手册</span>
          </li> -->
        </section>
        <section class="action-section">
          <li class="action-item user-center" @click="onUserCenter">
            <i class="iconfont iconqingsaoqingchu"></i>
            <span>个人中心</span>
          </li>
        </section>
        <section class="action-section">
          <li class="action-item" @click="onQuit">
            <i class="iconfont iconkaiguantuichu"></i>
            <span>退出登录</span>
          </li>
        </section>
      </ul>
		</article>
		<!-- mask -->
		<div class="actions-mask" v-show="showActions" @click.self="onToggleShow"></div>
	</div>
</template>

<script>
// import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { setToken } from '@/utils/tools';

export default {
	name: 'UserActions',
	components: {},
	data() {
		return {
			showActions: false,
			loading: true,
      userInfo: null,
			defaultAvatar: require('@/assets/images/logo1.png'),
		}
	},
  created() {
    this.userInfo = this.$GetUserInfo();
  },
  mounted() {
    eventBus.$on('updateUserInfo', () => {
      this.userInfo = this.$GetUserInfo();
    })
  },
	methods: {
    /**
     * @desc: 切换显示
     */
		onToggleShow() {
			this.showActions = !this.showActions;
		},
    /**
     * @desc: 用户中心
     */
    onUserCenter() {
      this.$router.push({
        name: 'PedestalUserCenter'
      });
      this.onToggleShow();
    },
    /**
     * @desc: 清除缓存
     */
    onClearCache() {
      const loading = this.$loading({
        text: '清除中...'
      });
      this.showActions = false;
      setTimeout(() => {
        loading.close();
        this.$message.success('清除成功!');
      }, 1500);
    },
    /**
		 * @desc: 退出登录
		 */
		onQuit() {
			// 移除路由文件重新获取一次
			setToken('');
			sessionStorage.clear();
			localStorage.clear();
			this.$router.replace({
				name: 'Login'
			});
			eventBus.$emit('updateRoute');
			// 刷新数据
			sessionStorage.removeItem('navHistoryList');
			this.$store.commit('setNavHistory', []);
			this.$store.commit('setTargetGlobalTab', '');
		}
	},
  beforeDestroy() {
    eventBus.$off('updateUserInfo');
  }
};
</script>

<style lang="less" scoped>
@height: 48px;
@partWidth: 280px;
.user-actions {
	max-width: 48px;
	.info-desc-box {
		display: block;
		box-sizing: border-box;
		padding: 12px 16px;
		cursor: pointer;
		display: flex;
    &.active{
      background: #78A9FF;
    }
		.iconfont {
			line-height: 24px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
      font-size: 17px;
			color: #fff;
		}
	}
	.action-menu{
		position: fixed;
		right: 5px;
		z-index: 2999;
    width: 240px;
		box-sizing: border-box;
		box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 8%);
		transition: all .2s;
		overflow: hidden;
    background: #fff;
    .base{
      width: 100%;
      box-sizing: border-box;
      height: 72px;
      border-bottom: 1px solid #E9EBF0;
      padding: 16px;
      display: flex;
      .avatar{
        height: 40px;
        width: 40px;
        margin-right: 16px;
        border-radius: 8px;
        object-fit: cover;
      }
      .username{
        flex: 1;
        box-sizing: border-box;
        padding: 9px 0;
        height: 100%;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #121619;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .actions{
      box-sizing: border-box;
      width: 100%;
      padding: 0 8px;
      .action-section{
        box-sizing: border-box;
        padding: 8px 0;
        border-bottom: 1px solid #E9EBF0;
        &:last-of-type{
          border-bottom: 0;
        }
        .action-item{
          display: flex;
          height: 40px;
          overflow: hidden;
          box-sizing: border-box;
          padding: 9px 10px;
          border-radius: 4px;
          cursor: pointer;
          &:hover{
            background: #E9EBF0;
          }
          .iconfont{
            font-size: 14px;
            color: #4D5358;
            line-height: 22px;
          }
          span{
            flex: 1;
            margin-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4D5358;
            line-height: 22px;
          }
        }
      }
    }
	}
	.actions-mask{
		position: fixed;
		height: 100vh;
		width: 100vw;
		background: transparent;
		top: 0;
		left: 0;
		z-index: 2998;
		cursor: pointer;
	}
}
</style>