<!--
 * @Description: 项目顶部
 * @Author: luocheng
 * @Date: 2021-11-05 16:33:29
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-08 14:09:34
-->
<template>
	<div class="header">
			<i class="iconfont iconcompany type-icon" title="按组织架构"></i>
		<el-select v-model="filterData.companyName">
			<el-option
				v-for="item in companyList"
				:key="item.value"
				:label="item.label"
				:value="item.value"
			></el-option>
		</el-select>
		<section class="search-box">
			<SearchBox v-model="filterData.keyWord"></SearchBox>
		</section>
	</div>
</template>

<script>
import SearchBox from '../../select/SearchBox';

export default {
	name: 'ProjectHeader',
	components: { SearchBox },
	props: {
		// 可选公司列表
		companyList: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			filterData: {
				type: '', // 类型 公司筛选 或 地址筛选
				companyName: '', // 公司名称
        keyWord: '' // 项目名称
			}
		};
	},
  watch: {
    filterData: {
      handler() {
        this.onEmit();
      },
      deep: true
    }
  },
  methods: {
    /**
     * @desc: 提交数据
     */
    onEmit() {
			console.log('提交项目筛选数据数据', this.filterData)
      this.$emit('search', this.filterData);
    }
  }
};
</script>

<style lang="less" scoped>
.header {
	height: 32px;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	.type-icon {
		margin-right: 12px;
		font-size: 16px;
		box-sizing: border-box;
		padding: 8px 10px;
		color: #161616;
		background: #fff;
		border-radius: 4px;
		overflow: hidden;
		cursor: pointer;
	}
	:deep(.el-select)  {
		width: 192px;
		height: 32px;
		overflow: hidden;
		border: none !important;
		border-radius: 2px;
		background: #fff;
		margin-right: 12px;
		.el-input__inner {
			border: none;
			border-radius: 2px;
		}
	}
	.search-box {
		flex: 1;
	}
}
</style>