<!--
 * @Description: 菜单列表
 * @Author: luocheng
 * @Date: 2021-10-30 13:52:21
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-27 11:09:26
-->
<template>
	<article
		class="child-nav"
		:style="{
			width: showChildMenu && menuList.length ? '202px' : '0px',
			padding: showChildMenu && menuList.length ? '0px' : '0px'
		}"
	>
		<header class="child-header">
			<!-- <el-divider>{{ currentPart && currentPart[nameUUID] }}</el-divider> -->
			<div class="name">
				<!-- <i class="iconfont iconc-myapp"></i> -->
				<img
					src="@/assets/images/menu-icon.png"
					alt=""
				>
				<h4>{{ currentPart && currentPart[nameUUID] }}</h4>
			</div>
		</header>
		<article class="menu-box">
			<div
				class="menu-list"
				v-if="menuList && menuList.length"
			>
				<!-- 菜单默认收起@忠哥 -->
				<!-- :default-openeds="defaultOpeneds" -->
				<el-menu
					default-active=""
					class="menu-main"
					:background-color="'#ffffff'"
					:text-color="'#303133'"
					:active-text-color="'#1eb2fe'"
					:default-openeds="defaultOpeneds"
				>
					<!-- eslint-disable -->
					<!-- 多级 -->
					<el-submenu
						v-if="
							item[typeUUID] === 2
							&& judgingArchiAPermission(item[archiTypeUUID], item[authUUID], true)
							&& judgingCustomLogic(item[logicsUUID])
							&& !item[hideUUID]
						"
						:index="item[urlUUID]"
						v-for="(item, index) in menuList"
						:key="index"
					>
						<template slot="title">
							<i
								class="group-icon"
								:class="item[iconUUID]"
								v-if="item[iconUUID].toString().indexOf('el-icon') === 0"
							></i>
							<i
								class="group-icon"
								:class="['iconfont', item[iconUUID] || 'iconyeqian']"
								v-else
							></i>
							<span class="group-title">{{ item[nameUUID] }}</span>
						</template>
						<span
							v-for="(child, cIndex) in item[childrenUUID]"
							:key="cIndex"
						>
							<!-- 暂时没有渲染多层 -->
							<!-- 页面 -->
							<el-menu-item
								:index="child[urlUUID] || child[gotoUrlUUID]"
								@click="onItem(child)"
								v-if="
									!child[hideUUID]
									&& judgingArchiAPermission(child[archiTypeUUID], child[authUUID], true)
									&& judgingCustomLogic(child[logicsUUID])
								"
								:class="{
									'is-active': isCurrentActive(child),
									'without-active': !isCurrentActive(child)
								}">
								<template slot="title">
									<i
										class="item-icon"
										:class="child[iconUUID]"
										v-if="child[iconUUID].toString().indexOf('el-icon') === 0"
									></i>
									<i
										class="item-icon"
										:class="['iconfont', child[iconUUID] || 'iconyeqian']"
										v-else
									></i>
									<span>{{ child[nameUUID] }}</span>
									<!-- <el-popover
										v-else
										popper-class="navlist-popper"
										width="80"
										placement="top-start"
										trigger="hover"
										:content="child[nameUUID]"
									>
										<span slot="reference">{{ child[nameUUID] }}</span>
									</el-popover> -->
								</template>
							</el-menu-item>
						</span>
					</el-submenu>
					<!-- 仅一级 -->
					<el-menu-item
						:index="item[urlUUID] || item[gotoUrlUUID]"
						v-else-if="
							judgingArchiAPermission(item[archiTypeUUID], item[authUUID], true)
							&& (!item[hideUUID])
							&& showLibrary(item[urlUUID] || item[gotoUrlUUID])
							&& judgingCustomLogic(item[logicsUUID])
						"
						@click="onItem(item)"
						:class="{
						'is-active': isCurrentActive(item),
						'without-active': !isCurrentActive(item)
					}"
					>
						<i
							class="item-icon"
							:class="item[iconUUID]"
							v-if="item[iconUUID].toString().indexOf('el-icon') === 0"
						></i>
						<i
							class="item-icon"
							:class="['iconfont', item[iconUUID] || 'iconyeqian']"
							v-else
						></i>
							<!-- v-if="item[nameUUID].length < 7" -->
						<span
							slot="title"
						>{{ item[nameUUID] }}</span>
						<!-- <el-popover
							v-else
							popper-class="navlist-popper"
							placement="top-start"
							width="80"
							trigger="hover"
							:content="item[nameUUID]"
						>
							<span slot="reference">{{ item[nameUUID] }}</span>
						</el-popover> -->
					</el-menu-item>
				</el-menu>
			</div>
		</article>
	</article>
</template>

<script>
import { Menu, MenuItem, Submenu } from 'element-ui';
import { mapState } from 'vuex';
import { checkMenuAuth } from '@/utils/tools';
import { judgingArchiAPermission, judgingCustomLogic } from '@/utils/tools';

export default {
	name: 'MenuList',
	components: {
		'el-menu': Menu,
		'el-submenu': Submenu,
		'el-menu-item': MenuItem,
	},
	data() {
		return {
			childrenUUID: '72da4bd8-c508-48c9-8e75-5ed59e6b09d2',
			typeUUID: '9b251b20-6276-4db5-8f8e-4ea03e4f79c3',
			authUUID: 'field617141d07b8dd',
			urlUUID: '7c5166f1-84aa-4639-83f6-7a2a07a518d9',
			iconUUID: 'a4938b11-2a01-46c9-b288-d86cdb5dbfc6',
			nameUUID: '97fb3c74-5e6b-4770-9fb6-75bf2695f30b',
			gotoUrlUUID: '32010f98-4519-43a2-83a2-f391ede54533',
			pageIDUUID: '532ca44e-18f5-4a0d-96d2-11bfd1b68992',
			gotoTypeUUID: '7814f48c-c312-464f-984c-7ac56bae30ca',
			pageUUID: 'field61b95865f2ccd',
			hideUUID: 'field61c02ecdd270b',
			childPathUUID: 'field61c1aa2aac54a',
			logicsUUID: 'field627cbb74ecc3e',
			// 当前地址
			currentPath: '',
			archiTypeUUID: 'field617e68031d866',
			archiType: ''
		};
	},
	computed: {
		...mapState(['globalConfig', 'currentPart', 'showChildMenu']),
		// 菜单列表
		menuList() {
			if (!this.currentPart) {
				return [];
			}
			return this.currentPart[this.childrenUUID] || [];
		},
		// 默认展开的数组
		defaultOpeneds() {
			if (!this.menuList || !this.menuList.length) return [];
			const result = [];
			const path = this.$route.path;
			for (let i = 0; i < this.menuList.length; i++) {
				const item = this.menuList[i];
				const url = item[this.urlUUID] || item[this.gotoUrlUUID];
				// && url.indexOf(path) > -1
				if (url && this.isChildPath(url, path)) {
					result.push(item[this.urlUUID] || item[this.gotoUrlUUID]);
				} else {
					const children = item[this.childrenUUID];
					// 老版本逻辑似乎存在问题
					// if (children && children.length && children.find(ele => ele[this.urlUUID].indexOf(path) > -1)) {
					if (children && children.length && children.find(ele => this.isChildPath(ele[this.urlUUID], path))) {
						result.push(item[this.urlUUID] || item[this.gotoUrlUUID]);
					}
				}
			}
			return result;
		}
	},
	watch: {
		$route: {
			handler(newv) {
				this.currentPath = newv.path;
			},
			deep: true
		}
	},
	created() {
		this.currentPath = window.location.pathname || '';
		this.archiType = this.$GetTargetArchi('archiType');
	},
	methods: {
		checkMenuAuth,
		judgingArchiAPermission,
		judgingCustomLogic,
		/**
		 * @desc: 是否显示
		 * 规则： 当前路由或子路由就进行显示
		 * @param {String} url 被对比路由
		 * @param {String} path 当前路由
		 */
		isChildPath(url, path) {
			if (!url || typeof url !== 'string') return false;
			if (url === path) return true;
			const arr = url.split('/');
			return arr.includes(path);
		},
		/**
		 * @desc: 判断是否为当前激活节点
		 */
		isCurrentActive(item) {
			const url = item[this.urlUUID];
			const childPathList = item[this.childPathUUID];
			if (this.currentPath === url) {
				return true;
			}
			if (childPathList && Array.isArray(childPathList)) {
				for (let i = 0; i < childPathList.length; i++) {
					if (window.location.href.indexOf(childPathList[i]) > -1) {
						return true;
					}
				}
			}
			return false;
		},
		/**
		 * @desc: 点击某个子路由节点
		 * @param {Object} pageObj
		 */
		onItem(pageObj) {
			const itemType = pageObj[this.typeUUID];
			const itemPath = pageObj[this.urlUUID];
			if ([0, 1, 3, 4].includes(itemType)) {
				this.$store.commit('setNavHistory', pageObj);
			}
			if (itemType === 0) {
				// 页面
				if (this.$route.path === itemPath) return;
				// 跳转页面
				this.$router.push({
					path: itemPath,
					query: {
						pageId: pageObj[this.pageIDUUID],
						pageUUID: pageObj[this.pageUUID]
					}
				});
			} else if (itemType === 1) {
				// 外链
				const hrefType = pageObj[this.gotoTypeUUID];
				if (hrefType === '_blank') {
					window.open(pageObj[this.gotoUrlUUID]);
				} else if (hrefType === '_self') {
					window.location.href = pageObj[this.gotoUrlUUID];
				} else {
					window.open(pageObj[this.gotoUrlUUID]);
				}
			} else if (itemType === 3) {
				if (this.$route.path === itemPath) return;
				// console.log('微前端页面', pageObj, '------', itemPath);
				// 微前端页面
				this.$router.push({
					path: itemPath,
					query: {
						type: 'micro'
					}
				});
			} else if (itemType === 4) {
				// console.log('仅跳转页面')
				if (this.$route.path === itemPath) return;
				this.$router.push({
					path: itemPath
				});
			}
		},
		/**
		 * @desc: 是否显示基本库配置
		 * @param {String} url
		 */
		showLibrary(url) {
			if (
				url !== '/child-app/base-data/subitem-library' &&
				url !== '/subitem-data-table' &&
				url !== '/child-app/subitem-data/standard-table-library' &&
				url !== '/child-app/base-data/standard-table-library'
			) {
				return true;
			}
			const privilegeList = ['蒋冬梅', '蒲亚军'];
			return privilegeList.includes(this.$GetUserInfo('name'));
		}
	}
};
</script>

<style lang="less" scoped>
@itemHeight: 32px;
.child-nav {
	width: 202px;
	box-sizing: border-box;
	padding: 20px 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: all 0.2s;
	box-shadow: 2px 0px 4px -2px rgba(0, 0, 0, 8%);
	user-select: none;
	.child-header {
		min-height: 60px;
		width: 100%;
		box-sizing: border-box;
		padding: 0 16px;
		.name {
			display: flex;
			width: 100%;
			box-sizing: border-box;
			padding: 20px 0 15px 0;
			border-bottom: 1px solid #f2f4f8;
			.iconfont {
				margin-right: 8px;
				font-size: 16px;
				line-height: 24px;
			}
			img {
				margin-top: 1px;
				height: 22px;
				width: 22px;
				margin-right: 8px;
			}
			h4 {
				flex: 1;
				min-height: 24px;
				line-height: 24px;
				// overflow: hidden;
				// text-overflow: ellipsis;
				// white-space: nowrap;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Medium;
				font-weight: 500;
				text-align: left;
				color: #343b4a;
				letter-spacing: 1px;
				word-break: break-all;
			}
		}
	}
	.menu-box {
		flex: 1;
		overflow: hidden;
		width: 100%;
		overflow-y: auto;
	}
}
.menu-list {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: 12px 0;
	padding-left: 10px;
	:deep(.el-menu) {
		// height: 100%;
		width: 100%;
		border: none !important;
		.el-menu-item {
			height: @itemHeight;
			line-height: @itemHeight;
			text-align: left;
			margin-bottom: 4px;
			min-width: 182px !important;
			padding-right: 10px;
			display: flex;
			.item-icon {
				line-height: @itemHeight;
				margin-right: 10px;
				color: #303133;
			}
			span {
				flex: 1;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			&.is-active {
				background: #e8f4ff !important;
				border-radius: 6px;
				color: var(--themeColor) !important;
				.item-icon {
					color: var(--themeColor) !important;
				}
			}
			&.without-active {
				background: #fff !important;
				color: #303133 !important;
				.item-icon {
					color: #303133 !important;
				}
			}
			&:hover {
				background: #e8f4ff !important;
				border-radius: 6px;
				color: var(--themeColor) !important;
				.item-icon {
					color: var(--themeColor) !important;
				}
			}
		}
		.el-submenu__title {
			height: @itemHeight;
			line-height: @itemHeight;
			text-align: left;
			margin-bottom: 4px;
			.group-icon {
				line-height: @itemHeight;
				margin-right: 10px;
				color: #303133;
			}
			&:hover {
				background: #e8f4ff !important;
				border-radius: 6px !important;
				color: var(--themeColor) !important;
				.group-icon {
					color: var(--themeColor) !important;
				}
				.el-submenu__icon-arrow {
					color: var(--themeColor) !important;
				}
			}
		}
	}
}
</style>
<style>
.navlist-popper {
	background: rgba(0, 0, 0, 0.6) !important;
	color: #fff !important;
	width: auto !important;
	min-width: 80px !important;
	padding: 5px !important;
	border-radius: 6px !important;
	padding: 5px !important;
	font-size: 12px !important;
}
.navlist-popper > .popper__arrow {
	display: none;
	/* background: rgba(0, 0, 0, 0.6) !important; */
}
</style>