import { render, staticRenderFns } from "./SelectArchi.vue?vue&type=template&id=f99e48de&scoped=true&"
import script from "./SelectArchi.vue?vue&type=script&lang=js&"
export * from "./SelectArchi.vue?vue&type=script&lang=js&"
import style0 from "./SelectArchi.vue?vue&type=style&index=0&id=f99e48de&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f99e48de",
  null
  
)

export default component.exports