<!--
 * @Description: 侧边栏
 * @Author: luocheng
 * @Date: 2021-10-29 21:09:52
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-09 14:31:59
-->
<template>
  <div class="nav-bar">
    <MenuPart></MenuPart>
    <!-- 子菜单 -->
    <MenuList></MenuList>
  </div>
</template>

<script>
import MenuList from './MenuList';
import MenuPart from './MenuPart';

export default {
  name: 'NavBar',
  components: {
    MenuList,
    MenuPart
  }
}
</script>

<style lang="less">
@levelOneWidth: 64px;
.nav-bar{
  width: auto;
  min-width: 64px;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  // border-right: 1px solid rgba(0, 0, 0, .08);
  box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 0.08); 
}
</style>