<!--
 * @Description: 基座外层框架的index
 * @Author: luocheng
 * @Date: 2021-11-02 09:53:57
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-29 14:04:24
-->
<template>
	<div class="pedestal-entry">
		<el-header
			class="pedestal-header"
			style="height: auto;"
		>
			<TopBar></TopBar>
		</el-header>
		<el-container class="pedestal-container">
			<el-aside
				style="width: auto;"
				v-show="!isFullScreen && !forcedPassword"
			>
				<NavBar />
			</el-aside>
			<el-main class="main">
				<!-- 历史记录 -->
				<NavHistory v-show="!isFullScreen && !forcedPassword"></NavHistory>
				<!-- 内容区域 -->
				<div class="content-box"
					:style="contentBoxStyle"
				>
					<div class="content-box-bg">
						<div class="pedestal-content" >
							<!-- 子应用 -->
							<div
								id="container"
								style="height: 100%;width:100%;overflow-y:auto"
								v-if="showContainer"
							></div>
							<!-- 常规路由 -->
							<div
								v-show="!showContainer"
								style="height: 100%;width: 100%"
							>
								<!-- 需要缓存的路由  中控台 -->
								<!-- <keep-alive v-show="$route.meta.keepAlive">
									<router-view v-show="$route.meta.keepAlive"></router-view>
								</keep-alive>
								<router-view v-if="!$route.meta.keepAlive"></router-view> -->
								<router-view></router-view>
							</div>
						</div>
					</div>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
import { Aside, Container, Header, Main } from 'element-ui';
import TopBar from './components/TopBar';
import NavBar from '@/components/layout/NavBar';
import NavHistory from './components/NavHistory';
import { start } from 'qiankun';
import { mapState } from 'vuex';

export default {
	name: 'Entry',
	components: {
		'el-container': Container,
		'el-header': Header,
		'el-aside': Aside,
		'el-main': Main,
		TopBar,
		NavBar,
		NavHistory
	},
	data() {
		return {
			showContainer: false,
			forcedPassword: 0
		};
	},
	computed: {
		...mapState([
			'systemConfig',
			'targetGlobalTab'
		]),
		// 根据当前点击的顶部菜单判断是否内容区全屏显示
		isFullScreen() {
			const fullTabs = ['dataview', 'bimConsole'];
			return !this.targetGlobalTab || fullTabs.includes(this.targetGlobalTab);
		},
		// 内容样式对象
		contentBoxStyle() {
			return {
				height: this.isFullScreen || +this.forcedPassword === 1 ? 'calc(100vh - 48px)' : 'calc(100vh - 98px)'
			}
		},
	},
	watch: {
		$route: {
			handler() {
				// 显示微应用容器，以各种微应用特定前缀开头
				this.forcedPassword = +this.$route.query?.forcedPassword;
				if (isNaN(this.forcedPassword)) {
					this.forcedPassword = 0;
				}
				this.showContainer =
					this.$route.path.indexOf('/child-app/') === 0 ||
					this.$route.path.indexOf('/child-app-bim/') === 0 ||
					this.$route.path.indexOf('/child-app-ts/') === 0 ||
					this.$route.path.indexOf('/app-vue/') === 0;
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			start({
				singular: false,
				sandbox: true,
				// sandbox: {
				// 	strictStyleIsolation: true,
				// 	experimentalStyleIsolation: true
				// },
				prefetch: 'all'
			});
		});
	}
};
</script>

<style lang="less" scoped>
@headerHeight: 48px;
.pedestal-entry {
	height: 100%;
	width: 100%;
	min-width: 1160px;
	min-height: 600px;
	font-family: PingFangSC, PingFangSC-Medium, Avenir, Helvetica, Arial,
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	display: flex;
	flex-direction: column;
	.pedestal-header {
		width: 100%;
		background: #0f62fe;
		box-sizing: border-box;
		padding: 0;
	}
	.pedestal-container {
		flex: 1;
		width: 100%;
		display: flex;
		overflow: hidden;
		.el-aside {
			position: relative;
			width: auto;
			box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 0.08);
			z-index: 20;
		}
		.el-main {
			flex: 1;
			overflow: hidden;
			box-sizing: border-box;
			padding: 0;
			position: relative;
			z-index: 10px;
			background: transparent;
			.content-box {
				box-sizing: border-box;
				width: 100%;
				flex: 1;
				background: #f2f4f8;
				overflow: hidden;
				overflow-y: auto;
				&.content-box-one{
					height: calc(100% - 50px);
				}
				&.content-box-two{
					height: calc(100vh - 98px);
				}
				.content-box-bg {
					height: 100%;
					width: 100%;
					box-sizing: border-box;
					padding: 16px;
					.pedestal-content {
						box-sizing: border-box;
						width: 100%;
						height: 100%;
						overflow: hidden;
						background: #fff;
						border-radius: 4px;
						box-shadow: 0px 0px 20px 0px rgba(72, 134, 255, 0.1);
						>#container{
							>div{
								height: 100%;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
#container {
	>div {
		height: 100%;
		width: 100%;
	}
}
</style>
