<!--
 * @Description: 架构信息
 * @Author: luocheng
 * @Date: 2021-11-05 16:02:30
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-11-01 14:13:58
-->
<template>
  <div class="archi-info select-archi-node" v-if="archiInfo">
    <h4 class="archi-title select-archi-node">{{ archiInfo.name || archiInfo.short_name || '' }}</h4>
		<p class="full-name select-archi-node">{{ archiInfo.parent_name || '' }}</p>
		<section class="action-item select-archi-node" @click="onFuture">
			<i class="iconfont icon-pre iconpingtaishuju"></i>
			<p class="label">{{ archiInfo.id.includes('org_companys') ? '企业' : '项目' }}看板<span style="color:#8d8d8d;font-size:12px">(开发中)</span></p>
			<i class="iconfont icon-go iconxiangyoujiantou2"></i>
		</section>
		<section class="action-item screen" @click="onFuture">
			<i class="iconfont icon-screen icondashboard"></i>
			<p class="label">可视化大屏<span style="color:#8d8d8d;font-size:12px">(开发中)</span></p>
			<i class="iconfont icon-go iconxiangyoujiantou2"></i>
		</section>
		<a
		v-if="targetArchi && targetArchi.id !== archiInfo.id"
			href="javascript:;"
			class="entry-btn"
			@click="onToggleArchi"
		>进入{{ archiInfo.id.includes('org_companys') ? '企业' : '项目' }}</a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data/index';

export default {
  name: 'ArchiInfo',
	computed: {
		...mapState(['cacheArchi', 'targetArchi', 'globalConfig']),
		// 架构信息
		archiInfo() {
			return this.cacheArchi || this.targetArchi;
		}
	},
	data() {
		return {
			childrenUUID: '72da4bd8-c508-48c9-8e75-5ed59e6b09d2',
			urlUUID: '7c5166f1-84aa-4639-83f6-7a2a07a518d9',
		}
	},
	methods: {
  /**
   * @desc: 切换企业
   */
		onToggleArchi() {
			// console.log(this.archiInfo, '999999archiInfo');
			this.$loading();
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'LoginFuncClass',
				type: 'behavior',
				funcName: 'ToggleArchBehavior',
				payload: {
					archi_id: this.archiInfo.id,
					archi_table:
						this.archiInfo.id.indexOf('companys') > -1 ? 'org_companys' : 'org_projects'
				}
			})
				.then((res) => {
					if (!res) {
						return false;
					}
					//模型上传需要审批的项目地址
					let routerApprovalModel = ['https://bim.xwjgpt.com/']
					this.$store.commit('setTargetArchi', {
            ...res.data.data,
						archiId: res.data.data.archi_id,
            archiType: this.archiInfo.id.indexOf('companys') > -1 ? 'company' : 'project',
						// 测试数据
						// sectionId: '38', // 部门ID
						// sectionName: '领导班子', // 部门名称
						id: res.data.data.data_id,
						modelApproval:routerApprovalModel.includes(process.env.VUE_APP_V3_HOST)?true:false,
						company_id: res.data.data.data.company_id || ''
          });
					localStorage.setItem("userRoles", res.data.data.permissions || '');
          // 刷新数据
					sessionStorage.removeItem('navHistoryList');
					this.$store.commit('setNavHistory', []);
					// 跳转到首页
					// 首页路由
					const routeObj = sessionStorage.getItem('indexPath');
					const indexPath = routeObj ? JSON.parse(routeObj).path : process.env.VUE_APP_HOME_PATH;
					this.$router.replace({
						path: indexPath
					})
					const pageObj = this.getPathObj(indexPath);
					this.$store.commit('setNavHistory', pageObj);
					this.$store.commit('setTargetGlobalTab', '');
					setTimeout(() => {
						window.location.reload();
					}, 300);
				})
				.catch((err) => {
					console.log(err);
					this.$loading().close();
				});
		},
		/**
		 * @desc: 获取路由对象
		 */
		getPathObj(path) {
			let result = null;
			for (let i = 0; i < this.globalConfig.length; i++) {
				if (this.globalConfig[i][this.urlUUID] === path) {
					result = this.globalConfig[i];
					break;
				}
				if (this.globalConfig[i][this.childrenUUID]) {
					const children = this.globalConfig[i][this.childrenUUID];
					for (let j = 0; j < children.length; j++) {
						if (children[j][this.urlUUID] === path) {
							result = children[j];
							break;
						}
						if (children[j][this.childrenUUID]) {
							const childrenChilds = children[j][this.childrenUUID];
							for (let k = 0; k < childrenChilds.length; k++) {
								if (childrenChilds[k][this.urlUUID] === path) {
									result = childrenChilds[k];
									break;
								}
							}
						}
					}
				}
			}
			return result;
		},
		/**
		 * @desc: 开发中
		 */
		onFuture() {
			this.$message({
				showClose: true,
				message: '功能开发中，敬请期待...'
			});
		}
	}
}
</script>

<style lang="less" scoped>
@itemWidth: 252px;
@itemHeight: 416px;
.archi-info{
  width: 100%;
  width: @itemWidth;
  height: @itemHeight;
  background: #fff;
	overflow: hidden;
	box-sizing: border-box;
	padding: 20px 16px;
	overflow: hidden;
  border-radius: 4px;
	.archi-title {
		width: 100%;
		font-size: 18px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: left;
		color: #161616;
		line-height: 26px;
		padding-bottom: 4px;
		word-break: break-all;
	}
	.full-name {
		width: 100%;
		padding-bottom: 8px;
		font-size: 14px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		text-align: justify;
		color: #8d8d8d;
		line-height: 22px;
		word-break: break-all;
		border-bottom: 1px solid #f2f4f8;
	}
	.action-item {
		display: flex;
		height: 32px;
		box-sizing: border-box;
		padding: 5px 0;
		margin-top: 12px;
		cursor: pointer;
		border-radius: 2px;
		&:hover {
			background: #d0e2ff;
		}
		&.screen {
			margin-top: 8px;
			.icon-pre {
				color: #9b94a7;
			}
		}
		.iconfont {
			line-height: 22px;
			font-size: 16px;
			&.icon-pre {
        padding-left: 5px;
				color: #33b1ff;
			}
			&.icon-screen{
        padding-left: 5px;
				color: #be95ff;
			}
			&.icon-go {
				color: #8d8d8d;
			}
		}
		.label {
			flex: 1;
			box-sizing: border-box;
			padding: 0 8px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: left;
			color: #161616;
			line-height: 22px;
			// color:#8d8d8d;
		}
	}
	.entry-btn {
		display: block;
		width: 100%;
		height: 32px;
		margin-top: 50px;
		background: #0f62fe;
		border-radius: 2px;
		text-align: center;
		font-size: 14px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		color: #ffffff;
		line-height: 32px;
	}
}
</style>