<!--
 * @Description: 入口文件顶部bar
 * @Author: luocheng
 * @Date: 2021-11-02 10:11:06
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-29 14:02:48
-->
<template>
	<div class="top-bar top-bar-use">
		<section class="logo">
			<img
				:src="projectObj[projectEnvironment].logo"
				:alt="projectObj[projectEnvironment].fullName"
				:class="{
					'is-nanhua': projectEnvironment === 'nh'
				}"
			/>
			<h3 class="title">{{ projectObj[projectEnvironment].fullName }}</h3>
			<!-- <h3 class="title">BIM+项目建管平台</h3> -->
			<span></span>
		</section>
		<!-- 管理中心 -->
		<section class="manage-center" :style="isUpdtePwd?'':'opacity: 0;'" v-if="!forcedPassword">
			<i
				class="iconfont iconc-myapp"
				@click="onFuture"
			></i>
			<span class="label">管理中心</span>
			<i class="iconfont iconxiangyoujiantou2"></i>
		</section>
		<!-- 模块 -->
		<ul class="nav-list" :style="isUpdtePwd?'':'opacity: 0;'">
			<template v-if="!forcedPassword">
				<li
					class="nav-item"
					v-for="item in topNav"
					:key="item.key"
					:class="{
						'active': item.key === topKey
					}"
					@click="onTopNav(item)"
				>{{ item.label }}</li>
			</template>
		</ul>
		<!-- 选择架构 -->
		<SelectArchi  v-show="isUpdtePwd" v-if="!forcedPassword"></SelectArchi>
		<!-- 信息 -->
		<section class="infos" >
			<i
				class="iconfont iconspeaker info-item"
				@click="onFuture"
         v-show="isUpdtePwd"
			></i>
			<i
				class="iconfont iconnotification info-item"
				@click="onFuture"
         v-show="isUpdtePwd"
			></i>
			<!-- <el-dropdown class="info-item">
				<i class="iconfont iconuser userinfo"></i>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item
						class="quit-item"
						@click.native="onQuit"
					>退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
			<!-- 头像 -->
			<UserActions></UserActions>
		</section>
	</div>
</template>

<script>
import SelectArchi from './SelectArchi';
import UserActions from './UserActions';
import eventBus from '@/plugins/eventBus';
import { setToken } from '@/utils/tools';
import { dataInterface } from '@/apis/data';
import { projectObj } from '@/config/projectConfig';
import { mapState } from 'vuex';

export default {
	name: 'TopBar',
	components: {
		SelectArchi,
		UserActions
	},
	data() {
		return {
			topKey: 'workbench',
			topNav: [
				{
					label: '工作台',
					key: 'workbench',
					fullScreen: false,
					archi: 'any'
				},
				// {
				// 	label: '大屏中心',
				// 	key: 'dataview',
				// 	fullScreen: true,
				// 	archi: 'project'
				// },
				{
					label: 'BIM中控台',
					key: 'bimConsole',
					fullScreen: true,
					archi: 'any'
				},
				// {
				// 	label: 'GIS中控台',
				// 	key: 'gis'
				// }
			],
			// 默认大屏ID
			dataviewId: 0,
			typeUUID: '9b251b20-6276-4db5-8f8e-4ea03e4f79c3',
			urlUUID: '7c5166f1-84aa-4639-83f6-7a2a07a518d9',
			pageIDUUID: '532ca44e-18f5-4a0d-96d2-11bfd1b68992',
			pageUUID: 'field61b95865f2ccd',
      isUpdtePwd:false,
			projectObj,
			forcedPassword: 0
		};
	},
	created() {
		this.forcedPassword = +this.$route.query?.forcedPassword;
		if (isNaN(this.forcedPassword)) {
			this.forcedPassword = 0;
		}
		const host = window.location.host;
		if (host === 'zssjzx.bimcc.net') {
			// 中设设计
			try {
				const index = this.topNav.findIndex(ele => ele.key === 'bimConsole');
				this.topNav.splice(index, 1, {
					...this.topNav[index],
					label: 'BIM&GIS中控台'
				})
			} catch (e) {
				console.log(e, '----错误1');
			}
		}
		this.topKey = this.$route.query.type || sessionStorage.getItem('targetGlobalTab') || 'workbench';
    if(localStorage.getItem('updatePwd') == 1){
      this.isUpdtePwd = true
    }
		this.onTopNav({ key: this.topKey }, true);
		this.getDataId();
	},
	computed: {
		...mapState(['projectEnvironment'])
	},
	methods: {
		/**
		 * @desc: 切换模块
		 * @param {Object} item
		 * @param {Boolean} inInit 初始化
		 */
		onTopNav(item, inInit = false) {
         if(localStorage.getItem('updatePwd') == 0 ) {
          // this.$message.error('请先修改密码!')
          return
      }
			if (!inInit) {
				if (item.key === 'dataview') {
					// 跳转大屏中心
					// this.$router.replace({
					// 	path: '/dataview-v3.0',
					// 	query: {
					// 		pageUUID: 'page61c175a829d46'
					// 	}
					// })
					if (!this.dataviewId) {
						this.$message.info('暂未配置大屏!');
						return false;
					}
					window.open(`${process.env.VUE_APP_BASE_URL}pc/dataview/show/${this.dataviewId}`, '_blank');
					return true;
				} else if (item.key === 'bimConsole') {
					// BIM中控台
					this.$router.replace({
						name: 'Entry',
						query: {
							type: 'bimConsole'
						}
					});
					this.$set(this.$route.query, 'isBIMConsole', 1);
				} else if (item.key === 'workbench') {
					// 跳转工作台
					// 跳转到历史记录第一个
					// console.log(JSON.parse(sessionStorage.getItem('navHistoryList')), '跳转工作台', this.topKey)
					const navHistoryList = JSON.parse(sessionStorage.getItem('navHistoryList'));
					if (!navHistoryList.length || !navHistoryList[navHistoryList.length - 1]) {
						if (sessionStorage.getItem('indexPath')) {
							this.$router.replace(JSON.parse(sessionStorage.getItem('indexPath')));
						} else {
							this.$router.replace({
								path: process.env.VUE_APP_HOME_PATH
							})
						}
					} else {
						const pathUUObj = navHistoryList[navHistoryList.length - 1];
						this.$router.replace({
							path: pathUUObj[this.urlUUID],
							query: this.getPathQuery(pathUUObj)
						})
					}
					// console.log(this.$router.history, '0000', this.topKey.key)
				}
			}
			this.topKey = item.key;
			this.$store.commit('setTargetGlobalTab', this.topKey);
		},
		/**
		 * @desc: 获取跳转的路由 query
		 */
		getPathQuery(pageObj) {
			const itemType = pageObj && pageObj[this.typeUUID];
			if (itemType === 0) {
				// 跳转页面
				return {
					pageId: pageObj[this.pageIDUUID],
					pageUUID: pageObj[this.pageUUID]
				}
			} if (itemType === 3) {
				// 微前端页面
				return {
					type: 'micro'
				}
			}
			return {};
		},
		/**
		 * @desc: 退出登录
		 */
		onQuit() {
			// 移除路由文件重新获取一次
			setToken('');
			sessionStorage.clear();
			localStorage.clear();
			this.$router.replace({
				name: 'Login'
			});
			eventBus.$emit('updateRoute');
			// 刷新数据
			sessionStorage.removeItem('navHistoryList');
			this.$store.commit('setNavHistory', []);
			this.$store.commit('setTargetGlobalTab', '');
		},
		/**
		 * @desc: 展开选择应用
		 */
		onFuture() {
			this.$message({
				showClose: true,
				message: '功能开发中，敬请期待...'
			});
		},
		/**
		 * @desc: 获取大屏默认绑定
		 */
		getDataId() {
			dataInterface({}, '/api/dataview/getDefault', 'GET').then((res) => {
				if (res.status === 200) {
					this.dataviewId = res.data.data;
				}
			}).catch(err => {
				console.log(err, '-----------');
			})
		}
	}
};
</script>

<style lang="less">
@height: 48px;
.top-bar {
	display: flex;
	min-height: @height;
	min-width: 860px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 16px;
	background: #4589ff;
	user-select: none;
	.logo {
		box-sizing: border-box;
		padding: 8px 0;
		display: flex;
		img {
			display: block;
			height: 32px;
			min-width: 32px;
			object-fit: cover;
			border-radius: 6px;
			&.is-nanhua{
				// background: #fff;
			}
		}
		.title {
			box-sizing: border-box;
			padding: 4px 16px;
			font-size: 16px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			text-align: left;
			color: #ffffff;
			line-height: 24px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		span {
			display: block;
			height: 18px;
			margin-top: 7px;
			width: 1px;
			background: #fff;
		}
	}
	.manage-center {
		box-sizing: border-box;
		padding: 12px 16px;
		display: flex;
		cursor: pointer;
		img {
			margin-top: 1px;
			height: 22px;
			width: 22px;
			margin-right: 8px;
		}
		.label {
			line-height: 24px;
			box-sizing: border-box;
			padding: 0 15px;
			color: #fff;
			font-size: 16px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		.iconfont {
			font-size: 16px;
			line-height: 24px;
			color: #fff;
		}
	}
	.nav-list {
		flex: 1;
		display: flex;
		min-height: @height;
		.nav-item {
			min-width: 112px;
			min-height: @height;
			box-sizing: border-box;
			padding: 12px 24px;
			text-align: center;
			opacity: 0.5;
			font-size: 16px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			color: #fff;
			opacity: 0.5;
			line-height: 24px;
			background: transparent;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			&:hover {
				color: #fff;
				background: rgb(77, 134, 232);
				opacity: 1;
			}
			&.active {
				color: #fff;
				opacity: 1;
				background: rgba(255, 255, 255, 0.2);
			}
		}
	}
	.infos {
		display: flex;
		box-sizing: border-box;
		padding-left: 16px;
		.info-item {
			// height: @height;
			height: 100%;
			width: @height;
			box-sizing: border-box;
			line-height: 24px;
			font-size: 16px;
			color: #fff;
			box-sizing: border-box;
			padding: 12px 0;
			text-align: center;
			cursor: pointer;
			&:hover {
				color: #fff;
				background: rgb(77, 134, 232);
				opacity: 1;
			}
			&.active {
				color: #fff;
				opacity: 1;
				background: rgba(255, 255, 255, 0.2);
			}
		}
	}
}
</style>