<!--
 * @Description: 企业
 * @Author: luocheng
 * @Date: 2021-11-05 16:18:17
 * @LastEditors: luocheng
 * @LastEditTime: 2021-11-05 19:01:52
-->
<template>
	<div class="enterprise">
		<SearchBox v-model="archiName"></SearchBox>
		<article class="enterprise-main">
			<!-- <section class="group item">
				<GroupItem
					:title="'集团'"
					:stylObj="styleObj"
				></GroupItem>
			</section> -->
			<section class="company item">
				<GroupItem
					:dataList="companyRenderData || []"
					:current="targetId"
					:title="'公司'"
					:stylObj="styleObj"
				></GroupItem>
			</section>
			<section class="company item">
				<!-- 信息 -->
				<ArchiInfo></ArchiInfo>
			</section>
		</article>
	</div>
</template>

<script>
import SearchBox from '../../select/SearchBox';
import GroupItem from '../GroupItem';
import ArchiInfo from '../ArchiInfo';

export default {
	name: 'Enterprise',
	components: {
		SearchBox,
		GroupItem,
		ArchiInfo
	},
	data() {
		return {
			styleObj: {
				height: '412px'
			},
			// 公司数据
			companyData: [],
			targetId: null,
			// 架构名称筛选
			archiName: ''
		};
	},
	created() {
		this.companyData = JSON.parse(localStorage.getItem('myCompany')) || [];
		this.targetId = this.$GetTargetArchi("id");
	},
	computed: {
		companyRenderData() {
			return this.companyData.filter(ele => {
				const name = ele.name || ele.short_name || '';
				return name.includes(this.archiName || '');
			})
		}
	}
};
</script>

<style lang="less" scoped>
@itemWidth: 252px;
.enterprise {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.enterprise-main {
		flex: 1;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding-top: 16px;
		height: 412px;
		.item {
			width: @itemWidth;
			height: 100%;
			border-radius: 4px;
			overflow: hidden;
      &.company{
        // flex: 1;
      }
		}
	}
}
</style>