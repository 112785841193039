<!--
 * @Description: 菜单历史
 * @Author: luocheng
 * @Date: 2021-11-02 11:28:03
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-13 17:10:40
-->
<template>
	<section class="history-box" v-if="historyList">
		<div class="tab-list">
			<el-tabs
				v-model="targetHistory"
				type="card"
				@tab-remove="onTabRemove"
				@tab-click="onTabs"
			>
				<el-tab-pane
					:key="index"
					v-for="(item, index) in historyList"
					:label="item && item[nameUUID]"
					:name="item && item[idUUID].toString()"
					@click="onTab(item)"
					:closable="item && item[urlUUID] !== indexPath"
				>
					<div class="tab-pane-label" slot="label" @contextmenu.prevent="openMenu($event, item)">
						<i v-if="item && item[iconUUID].toString().indexOf('el-icon') === 0" :class="item && item[iconUUID]"></i>
						<i v-else :class="['iconfont', item && item[iconUUID]]"></i>
						<span>{{ item && item[nameUUID] }}</span>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<ul v-if="visible" class="contextmenu el-dropdown-menu el-dropdown-menu--small" :style="{ left: left + 'px', top: top + 'px' }">
			<li class="el-dropdown-menu__item" @click="onClose('other')">
				<span class="item">
					<i class="el-icon-close"></i>
					<span>关闭其他</span>
				</span>
			</li>
			<li class="el-dropdown-menu__item" @click="onClose('left')">
				<span class="item">
					<i class="el-icon-back"></i>
					<span>关闭左侧</span>
				</span>
			</li>
			<li class="el-dropdown-menu__item" @click="onClose('right')">
				<span class="item">
					<i class="el-icon-right"></i>
					<span>关闭右侧</span>
				</span>
			</li>
			<li class="el-dropdown-menu__item" @click="onClose('all')">
				<span class="item">
					<i class="el-icon-close"></i>
					<span>关闭所有</span>
				</span>
			</li>
		</ul>
		<!-- 右侧操作 -->
		<el-dropdown>
			<!-- :class="{ 'header-tabs-more-active': active }" -->
			<span class="header-tabs-more">
				<span class="header-tabs-more-icon">
					<i class="box box-t"></i>
					<i class="box box-b"></i>
				</span>
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item>
					<span class="item" @click="onClose('other')">
						<i class="el-icon-close"></i>
						<span>关闭其他</span>
					</span>
				</el-dropdown-item>
				<el-dropdown-item>
					<span class="item" @click="onClose('left')">
						<i class="el-icon-back"></i>
						<span>关闭左侧</span>
					</span>
				</el-dropdown-item>
				<el-dropdown-item>
					<span class="item" @click="onClose('right')">
						<i class="el-icon-right"></i>
						<span>关闭右侧</span>
					</span>
				</el-dropdown-item>
				<el-dropdown-item>
					<span class="item" @click="onClose('all')">
						<i class="el-icon-close"></i>
						<span>关闭所有</span>
					</span>
				</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</section>
</template>

<script>
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	TabPane,
	Tabs
} from 'element-ui';
import { mapState } from 'vuex';

export default {
	name: 'NavHistory',
	components: {
		'el-tabs': Tabs,
		'el-tab-pane': TabPane,
		'el-dropdown': Dropdown,
		'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem
	},
	data() {
		return {
			targetHistory: '',
			idUUID: '4f6619f9-cd08-40b1-9487-c06441a82b6f',
			typeUUID: '9b251b20-6276-4db5-8f8e-4ea03e4f79c3',
			urlUUID: '7c5166f1-84aa-4639-83f6-7a2a07a518d9',
			iconUUID: 'a4938b11-2a01-46c9-b288-d86cdb5dbfc6',
			nameUUID: '97fb3c74-5e6b-4770-9fb6-75bf2695f30b',
			gotoUrlUUID: '32010f98-4519-43a2-83a2-f391ede54533',
			pageIDUUID: '532ca44e-18f5-4a0d-96d2-11bfd1b68992',
			gotoTypeUUID: '7814f48c-c312-464f-984c-7ac56bae30ca',
			childrenUUID: '72da4bd8-c508-48c9-8e75-5ed59e6b09d2',
			arciTypeUUID: 'field617e68031d866',
			childPathUUID: 'field61c1aa2aac54a',
			hideInNavUUID: 'field61c02ecdd270b',
			// tab菜单
			visible: false,
			top: 0,
			left: 0,
			// 首页
			indexPath: '',
			// 当前架构类型
			archiType: ''
		};
	},
	computed: {
		...mapState(['historyList', 'globalConfig'])
	},
	watch: {
		$route: {
			handler(newv) {
				// 页面子页面
				const targetPath = this.getPathObj(this.$route.path);
				const targetHistory = targetPath?.[this.idUUID]?.toString();
				if (targetHistory) {
					this.targetHistory = targetHistory;
				} else {
					// 进入详情的时候保持当前的选中即可
					console.log(newv, 'targetPathtargetPathtargetPath')
					// 子页面
					const parent = this.getParentIndex(this.globalConfig, newv?.fullPath);
					this.targetHistory = parent || this.targetHistory;
				}
			},
			deep: true,
			immediate: true
		},
    visible(value) {
        if (value) document.body.addEventListener('click', this.closeMenu)
        else document.body.removeEventListener('click', this.closeMenu)
      },
	},
	created() {
		this.archiType = this.$GetTargetArchi('archiType');
		const history = sessionStorage.getItem('navHistoryList');
		// 首页路由
		const routeObj = sessionStorage.getItem('indexPath');
		this.indexPath = routeObj ? JSON.parse(routeObj).path : process.env.VUE_APP_HOME_PATH;
		if (history) {
			const historyList = JSON.parse(history);
			this.$store.commit('setNavHistory', historyList);
			const targetUrl = this.$route.path;
			for (let i = 0; i < this.historyList.length; i++) {
				if (!this.historyList[i]) break;
				if (this.historyList[i][this.urlUUID] === targetUrl) {
					this.targetHistory = this.historyList[i][this.idUUID].toString();
				}
			}
		} else {
			let path = this.$route.path === '/entry' ? this.indexPath : this.$route.path;
			let pageObject = this.getPathObj(path);
			console.log(pageObject, '101010101010101010',this.$route.path, this.indexPath, path);
			if (!pageObject) {
				// 直接进入中控台设置首页为历史记录
			}
			this.$store.commit('setNavHistory', pageObject);
		}
		this.userInfo = this.$GetUserInfo();
	},
	methods: {
		/**
		 * @desc: 获取父级tabIndex
		 * @param {Array} arr 菜单类表
		 * @param {String} path 路径
		 */
		getParentIndex(arr, path, parent = null) {
			if (!path || !arr || !arr.length) {
				return '';
			}
			let result = '';
			for (let i = 0; i < arr.length; i++) {
				parent = arr[i];
				const pathList = arr[i][this.childPathUUID] || [];
				if (pathList && pathList.length) {
					for (let j = 0; j < pathList.length; j++) {
						if (path.indexOf(pathList) > -1) {
							result = arr[i][this.idUUID]?.toString()
							return result;
						}
					}
				}
				const children = arr[i][this.childrenUUID];
				if (children && children.length) {
					result = this.getParentIndex(children, path, parent);
					if (result) {
						return result;
					}
				}
			}
			return result;
		},
		/**
		 * @desc: 切换显示
		 */
		onToggleShow() {
			this.$store.commit('changeChildShowStatus');
		},
		/**
		 * @desc: 移除tab
		 * @param {Object} data
		 */
		onTabRemove(data) {
			const index = this.historyList.findIndex(
				(ele) => +ele[this.idUUID] === +data
			);
			if (+data === +this.targetHistory) {
				// 删除当前节点之后需要打开新页面
				if (this.historyList.length > 1) {
					if (index === this.historyList.length - 1) {
						// 删除最后一个
						this.onTab(this.historyList[index - 1]);
					} else if (index < this.historyList.length - 1) {
						// 删除0 - len - 1
						this.onTab(this.historyList[index + 1]);
					}
				} else {
					// 跳转首页
					const pathObj = this.getPathObj(this.indexPath);
					if (pathObj) {
						this.onTab(pathObj);
					}
				}
			}
			this.$store.commit('removeNavHistory', index);
		},
		/**
		 * @desc: 点击tab
		 */
		onTabs() {
			const pageObj = this.historyList.find(
				(ele) => +ele[this.idUUID] === +this.targetHistory
			);
			this.onTab(pageObj);
		},
		/**
		 * @desc: 获取路由对象
		 */
		getPathObj(path) {
			let result = null;
			for (let i = 0; i < this.globalConfig.length; i++) {
				if (this.globalConfig[i][this.urlUUID] === path && this.globalConfig[i][this.hideInNavUUID] !== 1  && this.isSameTypeMenu(this.globalConfig[i])) {
					result = this.globalConfig[i];
					break;
				}
				if (this.globalConfig[i][this.childrenUUID]) {
					const children = this.globalConfig[i][this.childrenUUID];
					for (let j = 0; j < children.length; j++) {
						if (children[j][this.urlUUID] === path && children[j][this.hideInNavUUID] !== 1 && this.isSameTypeMenu(children[j])) {
							result = children[j];
							break;
						}
						if (children[j][this.childrenUUID]) {
							const childrenChilds = children[j][this.childrenUUID];
							for (let k = 0; k < childrenChilds.length; k++) {
								if (childrenChilds[k][this.urlUUID] === path && children[j][this.hideInNavUUID] !== 1 && this.isSameTypeMenu(childrenChilds[k])) {
									result = childrenChilds[k];
									break;
								}
							}
						}
					}
				}
			}
			return result;
		},
		/**
   * @desc: 判断是否为同架构类型菜单
   * @param {Object} menu
   */
		isSameTypeMenu(menu) {
			return !menu[this.arciTypeUUID] || menu[this.arciTypeUUID].indexOf(this.archiType) > -1;
		},
		/**
		 * @desc: 点击某个节点
		 * @param {Object} pageObj
		 */
		onTab(pageObj) {
			const itemType = pageObj[this.typeUUID];
			const itemPath = pageObj[this.urlUUID];
			this.targetHistory = pageObj[this.idUUID].toString() || '';
			if ([0, 1, 3, 4].includes(itemType)) {
				this.$store.commit('setNavHistory', pageObj);
			}
			if (itemType === 0) {
				// 页面
				if (this.$route.path === itemPath) return;
				// 跳转页面
				this.$router.push({
					path: itemPath,
					query: {
						pageId: pageObj[this.pageIDUUID],
						pageUUID: pageObj['field61b95865f2ccd']
					}
				});
			} else if (itemType === 1) {
				// 外链
				const hrefType = pageObj[this.gotoTypeUUID];
				if (hrefType === '_blank') {
					window.open(pageObj[this.gotoUrlUUID]);
				} else if (hrefType === '_self') {
					window.location.href = pageObj[this.gotoUrlUUID];
				} else {
					window.open(pageObj[this.gotoUrlUUID]);
				}
			} else if (itemType === 3) {
				if (this.$route.path === itemPath) return;
				// 微前端页面
				this.$router.push({
					path: itemPath,
					query: {
						type: 'micro'
					}
				});
			} else if (itemType === 4) {
				// console.log('仅跳转页面')
				if (this.$route.path === itemPath) return;
				this.$router.push({
					path: itemPath
				});
			}
		},
		/**
		 * @desc: 关闭
		 * @param {String} type all left right other
		 */
		onClose(type) {
			const resultArr = [];
			if (type === 'all') {
				// 所有
				// const routeObj = sessionStorage.getItem('indexPath');
				const pathObj = this.getPathObj(this.indexPath);
				if (pathObj) {
					resultArr.push(pathObj);
					this.onTab(pathObj);
				}
			} else if (type === 'other') {
				// 其他
				resultArr.push(
					this.historyList.find(
						(ele) => +ele[this.idUUID] === +this.targetHistory
					)
				);
			} else if (type === 'left') {
				// 左侧
				const index = this.historyList.findIndex(
					(ele) => +ele[this.idUUID] === +this.targetHistory
				);
				for (let i = 0; i < this.historyList.length; i++) {
					if (i >= index) {
						resultArr.push(this.historyList[i]);
					}
				}
			} else if (type === 'right') {
				// 右侧
				const index = this.historyList.findIndex(
					(ele) => +ele[this.idUUID] === +this.targetHistory
				);
				for (let i = 0; i < this.historyList.length; i++) {
					if (i <= index) {
						resultArr.push(this.historyList[i]);
					}
				}
			}
			// console.log(resultArr, 'resultArrresultArrresultArr');
			this.$store.commit('setNavHistory', resultArr);
      this.closeMenu();
		},
		/**
		 * @desc: tab 菜单
		 * @param {Object} e
		 * @param {Object} item
		 * @return {*}
		 */
		openMenu(e) {
			const offsetLeft = this.$el.getBoundingClientRect().left;
			const offsetWidth = this.$el.offsetWidth;
			const maxLeft = Math.round(offsetWidth);
			const left = Math.round(e.clientX - offsetLeft);
			if (left > maxLeft) this.left = maxLeft;
			else this.left = left;
			this.top = Math.round(e.clientY - 60);
			this.visible = true;
		},
    /**
     * @desc: 关闭
     * @param {*}
     * @return {*}
     */
    closeMenu() {
      this.visible = false;
    }
	}
};
</script>

<style lang="less" scoped>
.history-box {
	position: relative;
	width: 100%;
	box-sizing: border-box;
	padding: 8px 20px 0px 10px;
	height: 50px;
	box-sizing: border-box;
	padding-right: 50px;
	display: flex;
	user-select: none;
	.tab-list {
		flex: 1;
		width: 100%;
		height: 100%;
		.el-tabs--card > .el-tabs__header {
			border: none;
		}
		:deep(.el-tabs)  {
			box-sizing: border-box;
			.el-tabs__header {
				border: none !important;
				margin: 0;
			}
			.el-tabs__content {
				display: none;
			}
			.el-tabs__nav {
				height: 42px;
				border: none;
			}
			.el-tabs__item {
				box-sizing: border-box;
				// margin: 0 5px;
				margin-top: 2px;
				height: 100%;
				box-sizing: border-box;
				line-height: 38px;
				font-family: PingFangSC, PingFangSC-Medium;
				border: none;
				transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
				margin-right: -18px;
				padding: 0 30px 0 30px;
				&:hover {
					padding: 0 30px 0 30px;
					// color: #515a6e;
					color: @theme;
					// background: #E9EBF0;
					background: #dee1e6                     ;
					mask: url('~@/assets/images/layout/smoot-tab.png');
					mask-size: 100% 100%;
					.tab-pane-label {
						i {
							color: @theme;
						}
					}
				}
				&.is-active {
					padding: 0 30px 0 30px;
					color: @theme;
					background: #e8f4ff;
					outline: none;
					mask: url('~@/assets/images/layout/smoot-tab.png');
					mask-size: 100% 100%;
					font-weight: bold;
					.tab-pane-label {
						i {
							color: @theme;
						}
					}
				}
			}
			.tab-pane-label {
				display: inline-block;
				height: 36px;
				overflow: hidden;
				.iconfont {
					color: #303133;
				}
				i {
					color: #303133;
					line-height: 38px;
					margin-right: 5px;
				}
				span {
					line-height: 38px;
				}
			}
			.el-icon-close {
				margin-top: -22px;
			}
			.el-tabs__nav-prev {
				line-height: 42px;
			}
			.el-tabs__nav-next {
				line-height: 42px;
			}
			.el-tabs__new-tab {
				display: none;
			}
		}
	}
	.contextmenu {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	user-select: none;
	}
}
</style>
<style lang="less">
.history-box {
	background: #fff;
	box-shadow: 0 1px 4px rgba(0, 21, 14, 0.8);
	box-shadow: 5px 5px 20px 5px rgba(72, 134, 255, 0.1);
	position: relative;
	user-select: none;
	.el-dropdown {
		position: absolute;
		right: 16px;
		top: 19px;
		cursor: pointer;
		transition: all 0.3s;
		user-select: none;
		&:hover {
			transform: rotate(90deg);
			.box-t {
				&:before {
					transform: rotate(45deg) !important;
				}
			}
			.box:before,
			.box:after {
				background: @theme!important;
			}
		}
	}
	.header-tabs-more {
		.header-tabs-more-icon {
			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
				color 0.1s, font-size 0s;
			// transform: rotate(90deg);
			.box-t {
				&:before {
					transform: rotate(0deg);
				}
			}
			.box:before,
			.box:after {
				background: #9a9a9a;
			}
			.box {
				position: relative;
				display: block;
				width: 14px;
				height: 8px;
				&:before {
					position: absolute;
					top: 2px;
					left: 0;
					width: 6px;
					height: 6px;
					content: '';
					background: #9a9a9a;
				}
				&:after {
					position: absolute;
					top: 2px;
					left: 8px;
					width: 6px;
					height: 6px;
					content: '';
					background: #9a9a9a;
				}
			}
			.box-t {
				&:before {
					transition: transform 0.3s ease-out 0.3s;
				}
			}
		}
	}
	.el-dropdown-menu__item{
		user-select: none!important;
	}
}
</style>