<!--
 * @Description: 左侧主要菜单
 * @Author: luocheng
 * @Date: 2021-10-30 14:39:36
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-12 16:57:37
-->
<template>
	<div class="menu-part">
		<i
			class="icon-box"
			:class="['iconfont', toggleIcon]"
			@click="onToggleNav"
		></i>
		<div
			class="nav-one-list"
			style="height: calc(100vh-80px)"
		>
			<!-- eslint-disable -->
				<!-- v-if="!item[hideUUID] && (!item[arciTypeUUID] || (item[arciTypeUUID] && item[arciTypeUUID] === archiType))" -->
			<div
				class="menu-item"
				v-for="item in globalConfig"
				v-if="!item[hideUUID]
					&& judgingArchiAPermission(item[arciTypeUUID], item[authUUID], true)
					&& judgingCustomLogic(item[logicsUUID])
				"
				:key="item[idUUID]"
				:class="{
					'active':activeObject && item[idUUID] === activeObject[idUUID]
				}"
				@click="onMenu(item, true)"
			>
				<i :class="item[iconUUID] || 'el-icon-warning-outline'" v-if="item[iconUUID].toString().indexOf('el-icon') === 0"></i>
				<i :class="['iconfont', item[iconUUID] || 'iconyeqian']" v-else></i>
				<strong>
					{{ item[nameUUID] }}
				</strong>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { judgingArchiAPermission, judgingCustomLogic } from '@/utils/tools';

export default {
	name: 'MenuPart',
  data() {
    return {
      partList: [],
			// 当前所选菜单
			activeObject: null,
			childrenUUID: '72da4bd8-c508-48c9-8e75-5ed59e6b09d2',
			idUUID: '4f6619f9-cd08-40b1-9487-c06441a82b6f',
			typeUUID: '9b251b20-6276-4db5-8f8e-4ea03e4f79c3',
			urlUUID: '7c5166f1-84aa-4639-83f6-7a2a07a518d9',
			iconUUID: 'a4938b11-2a01-46c9-b288-d86cdb5dbfc6',
			nameUUID: '97fb3c74-5e6b-4770-9fb6-75bf2695f30b',
			gotoUrlUUID: '32010f98-4519-43a2-83a2-f391ede54533',
			pageIDUUID: '532ca44e-18f5-4a0d-96d2-11bfd1b68992',
			gotoTypeUUID: '7814f48c-c312-464f-984c-7ac56bae30ca',
			arciTypeUUID: 'field617e68031d866',
			logicsUUID: 'field627cbb74ecc3e',
			pageUUID: 'field61b95865f2ccd',
			archiType: '',
			hideUUID: 'field61c02ecdd270b',
			authUUID: 'field617141d07b8dd'
    }
  },
  computed: {
		...mapState([
			'globalConfig', // 全局的路由表配置
			'showChildMenu', // 子菜单显示
		]),
		// 图标
		toggleIcon() {
			return this.showChildMenu ? ' iconfold_' : 'iconfold';
		}
	},
  created() {
		this.archiType = this.$GetTargetArchi('archiType');
		let currentPart = sessionStorage.getItem('currentPart');
		if (currentPart) {
			currentPart = JSON.parse(currentPart);
			if (this.globalConfig.find(ele => ele[this.idUUID] === currentPart[this.idUUID])) {
				this.onMenu(currentPart, true);
			}
		}
  },
	watch: {
		$route: {
			handler() {
				const globalConfig = this.globalConfig || JSON.parse(sessionStorage.getItem('pageData')) || [];
				this.onMenu(this.getPathObj(this.$route.path, globalConfig), false);
			},
			deep: true,
			immediate: true
		}
	},
  methods: {
		judgingArchiAPermission,
		judgingCustomLogic,
		/**
		 * @desc: 切换显示
		 */
		onToggleNav() {
			this.$store.commit('changeChildShowStatus');
		},
		/**
		 * @desc: 获取路由对象
		 */		
		getPathObj(path, globalConfig) {
			let result = null;
			for (let i = 0; i < globalConfig.length; i++) {
				if (globalConfig[i][this.urlUUID] === path && globalConfig[i][this.hideUUID] !== 1 && this.isSameTypeMenu(globalConfig[i])) {
					result = globalConfig[i];
					break;
				}
				if (globalConfig[i][this.childrenUUID]) {
					const children = globalConfig[i][this.childrenUUID];
					// 子节点
					for (let j = 0; j < children.length; j++) {
						if (children[j][this.urlUUID] === path && children[j][this.hideUUID] !== 1 && this.isSameTypeMenu(children[j])) {
							result =  globalConfig[i];
							break;
						}
						// 孙节点
						if (children[j][this.childrenUUID]) {
							const childs = children[j][this.childrenUUID];
							for (let k = 0; k < childs.length; k++) {
								if (childs[k][this.urlUUID] === path && childs[k][this.hideUUID] !== 1 && this.isSameTypeMenu(childs[k])) {
									result = globalConfig[i];
									break;
								}
							}
							if (result) {
								break;
							}
						}
					}
					if (result) {
						break;
					}
				}
			}
			return result
		},
  /**
   * @desc: 判断是否为同架构类型菜单
   * @param {菜单} menu
   * @return {*}
   */
		isSameTypeMenu(menu) {
			return !menu[this.arciTypeUUID] || menu[this.arciTypeUUID].indexOf(this.archiType) > -1;
		},
		/**
		 * @desc: 点击某个节点
		 * @param {Object} pageObj
		 */
		onMenu(pageObj, toggleShow) {
			if (!pageObj) return;
			// 切换侧边栏显示
			if (toggleShow) {
				this.$store.commit('changeChildShowStatus', pageObj && pageObj[this.childrenUUID] && pageObj[this.childrenUUID].length)
			}
			this.activeObject = pageObj;
			this.$store.commit('serCurrentPart', pageObj);
			const itemType = pageObj && pageObj[this.typeUUID];
			const itemPath = pageObj[this.urlUUID];
			if ([0, 1, 3, 4].includes(itemType)) {
				this.$store.commit('setNavHistory', pageObj);
			}
			if (itemType === 0) {
				// 页面
				if (this.$route.path === itemPath) return;
				// 跳转页面
				this.$router.push({
					path: itemPath,
					query: {
						pageId: pageObj[this.pageIDUUID],
						pageUUID: pageObj[this.pageUUID]
					}
				});
			} else if (itemType === 1) {
				// 外链
				const hrefType = pageObj[this.gotoTypeUUID];
				if (hrefType === '_blank') {
					window.open(pageObj[this.gotoUrlUUID]);
				} else if (hrefType === '_self') {
					window.location.href = pageObj[this.gotoUrlUUID];
				} else {
					window.open(pageObj[this.gotoUrlUUID]);
				}
			} else if (itemType === 3) {
				if (this.$route.path === itemPath) return;
				// console.log('微前端页面', pageObj, '------', itemPath);
				// 微前端页面
				this.$router.push({
					path: itemPath,
					query: {
						type: 'micro'
					}
				});
			} else if (itemType === 4) {
				// console.log('仅跳转页面')
				if (this.$route.path === itemPath) return;
				this.$router.push({
					path: itemPath
				});
			}
		}
  }
};
</script>

<style lang="less">
.menu-part {
	width: 64px;
	height: 100%;
	background: #f2f4f8;
	display: flex;
	flex-direction: column;
	overflow: hidden;
  box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 8%);
	user-select: none;
	.icon-box {
		height: 50px;
		line-height: 16px;
		font-size: 16px;
		color: #8d8d8d;
		cursor: pointer;
		box-sizing: border-box;
		padding: 17px 0;
		text-align: center;
	}
	.nav-one-list {
		// width: calc(100% + 15px);
		width: 100%;
		flex: 1;
		overflow: hidden;
		overflow-y: auto;
		.menu-item {
			position: relative;
			width: 64px;
			min-height: 64px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 10px 5px;
			cursor: pointer;
			margin-bottom: 10px;
			margin: 0;
			&.active {
				background: #fff;
				opacity: 1;
				i, strong{
					color: #409EFF;
				}
				// &:after {
				// 	position: absolute;
				// 	right: 0;
				// 	width: 0;
				// 	height: 0;
				// 	top: 10px;
				// 	overflow: hidden;
				// 	content: '';
				// 	margin-top: 14px;
				// 	border-color: transparent #fff transparent transparent;
				// 	border-style: solid dashed dashed;
				// 	border-width: 8px;
				// }
			}
			i,strong{
				width: 100%;
				color: #202126;
				font-weight: 400;
				text-align: center;
			}
			i {
				font-size: 18px;
				font-family: PingFangSC, PingFangSC-Regular;
			}
			strong {
				margin-top: 5px;
				font-size: 12px;
				line-height: 20px;
				font-family: PingFangSC, PingFangSC-Regular;
			}
		}
	}
}
</style>