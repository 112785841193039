<!--
 * @Description: 最近查看
 * @Author: luocheng
 * @Date: 2021-11-05 14:58:33
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-13 17:11:50
-->
<template>
	<div class="recent select-archi-node">
		<SearchBox v-model="filterName"></SearchBox>
		<article class="recent-main select-archi-node">
			<div class="recent-child archi-list select-archi-node">
				<section class="compny item select-archi-node" v-if="!isProjectLevel">
					<GroupItem
						:title="'企业'"
						:styleObj="styleObj"
						:dataList="historyCompanys"
						:current="targetId"
					></GroupItem>
				</section>
				<section class="project item select-archi-node"
					:style="{
						marginTop: isProjectLevel ? 0 : '12px'
					}"
				>
					<GroupItem
						:title="'项目'"
						:styleObj="styleObj"
						:dataList="historyProjects"
						:current="targetId"
					></GroupItem>
				</section>
			</div>
			<div class="recent-child archi-infos select-archi-node">
				<ArchiInfo></ArchiInfo>
			</div>
		</article>
	</div>
</template>

<script>
import SearchBox from '../../select/SearchBox';
import GroupItem from '../GroupItem';
import ArchiInfo from '../ArchiInfo';

export default {
	name: 'RecentView',
	components: {
		SearchBox,
		GroupItem,
		ArchiInfo
	},
  props: {
    // 最近登录 
    historyArchi: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
	data() {
		return {
			styleObj: {
				height: '200px'
			},
			filterName: '',
      historyProjects: [],
      historyCompanys: [],
			// 当前选中
			targetId: null,
			isProjectLevel: 0
		};
	},
  created() {
		this.isProjectLevel = +process.env.VUE_APP_IS_PROJECT_LEVEL;
		if (this.isProjectLevel) {
			this.styleObj = {
				height: '416px'
			}
		}

    const historyProjects = this.historyArchi.projects || [];
		this.historyProjects = historyProjects.map(ele => {
			return {
				...ele,
				id: ele.archi_type || ele.id
			}
		})
    const historyCompanys = this.historyArchi.companys || [];
		this.historyCompanys = historyCompanys.map(ele => {
			return {
				...ele,
				id: ele.archi_type || ele.id
			}
		})
		this.targetId = this.$GetTargetArchi("id");
    // console.log(this.historyCompanys, this.historyProjects, '444444444')
  }
};
</script>

<style lang="less" scoped>
@itemWidth: 252px;
.recent {
	display: flex;
	flex-direction: column;
	.recent-main {
		flex: 1;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		padding-top: 16px;
		.recent-child {
			width: @itemWidth;
			height: 100%;
			overflow: hidden;
			&.archi-list {
				.project {
					margin-top: 12px;
				}
			}
		}
	}
}
</style>