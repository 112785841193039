<!--
 * @Description: 架构信息item
 * @Author: luocheng
 * @Date: 2021-11-03 10:21:48
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-31 14:44:03
-->
<template>
	<div class="archi-item"
		:class="{
			'is-active': cacheArchi ? cacheArchi.id === archiData.id : targetArchi && targetArchi.id === archiData.id
		}"
	>
		<!-- is-active -->
		<div class="archi-item-content">
			<img
				:src="getCover(archiData)"
				alt="cover"
				class="cover"
			>
			<p class="label">{{ archiData.short_name }}</p>
			<i
				:class="['iconfont', archiData.showChildren ? 'iconxiangshangjiantou1' : 'iconxiangxiajiantou1']"
				v-if="archiData.children && archiData.children.length"
			></i>
			<i class="iconfont iconselect-duigou" v-else-if="targetArchi.id === archiData.id"></i>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'ArchiItem',
	props: {
		// 架构数据
		archiData: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 层级
		level: {
			type: Number,
			required: false,
			default: 0
		},
		// 图标类型 showSelect
		iconType: {
			type: String,
			required: false,
			default: ''
		}
	},
	data() {
		return {
			defaultAvatar: require('@/assets/images/test/project.jpeg')
		}
	},
	computed: {
		...mapState(['cacheArchi', 'targetArchi'])
	},
	methods: {
		/**
		 * @desc: 获取封面图
		 * @param {Object} data
		 * @return {*}
		 */
		getCover(data) {
			if (!data?.photos) {
				return this.defaultAvatar;
			}
			const { photos } = data;
			let photoList = [];
			if (Array.isArray(photos)) {
				photoList = photos;
			} else {
				try {
					photoList = JSON.parse(photos);
				} catch (err) {
					console.log(err);
				}
			}
			if (!photoList.length) {
				return this.defaultAvatar;
			}
			const { thumb_path, path, url } = photoList[0]
			return thumb_path || path || url || this.defaultAvatar;
		}
	}
};
</script>

<style lang="less" scoped>
@height: 40px;
.archi-item {
	// margin-bottom: 4px;
	overflow: hidden;
  &.is-active {
			background: #d0e2ff;
		}
	&:hover {
		background: #d0e2ff;
	}
	.archi-item-content {
		height: @height;
		display: flex;
		width: 100%;
		box-sizing: border-box;
		// background: #fff;
		overflow: hidden;
		padding: 5px 0;
		border-radius: 2px;
		cursor: pointer;
		&.is-active {
			// background: #d0e2ff;
		}
		&:hover {
			// background: #d0e2ff;
		}
		.cover {
			margin-top: 1px;
			height: 20px;
			width: 20px;
			margin-top: calc((@height - 10px - 20px) / 2);
			overflow: hidden;
			object-fit: cover;
			background: #e0e0e0;
			border-radius: 4px;
		}
		.label {
			flex: 1;
			box-sizing: border-box;
			padding: 0 8px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: left;
			color: #161616;
			line-height: calc(@height - 10px);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.iconfont {
			line-height: calc(@height - 10px);
			font-size: 16px;
			color: #161616;
			box-sizing: border-box;
		}
	}
}
</style>