var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"child-nav",style:({
		width: _vm.showChildMenu && _vm.menuList.length ? '202px' : '0px',
		padding: _vm.showChildMenu && _vm.menuList.length ? '0px' : '0px'
	})},[_c('header',{staticClass:"child-header"},[_c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("@/assets/images/menu-icon.png"),"alt":""}}),_c('h4',[_vm._v(_vm._s(_vm.currentPart && _vm.currentPart[_vm.nameUUID]))])])]),_c('article',{staticClass:"menu-box"},[(_vm.menuList && _vm.menuList.length)?_c('div',{staticClass:"menu-list"},[_c('el-menu',{staticClass:"menu-main",attrs:{"default-active":"","background-color":'#ffffff',"text-color":'#303133',"active-text-color":'#1eb2fe',"default-openeds":_vm.defaultOpeneds}},_vm._l((_vm.menuList),function(item,index){return (
						item[_vm.typeUUID] === 2
						&& _vm.judgingArchiAPermission(item[_vm.archiTypeUUID], item[_vm.authUUID], true)
						&& _vm.judgingCustomLogic(item[_vm.logicsUUID])
						&& !item[_vm.hideUUID]
					)?_c('el-submenu',{key:index,attrs:{"index":item[_vm.urlUUID]}},[_c('template',{slot:"title"},[(item[_vm.iconUUID].toString().indexOf('el-icon') === 0)?_c('i',{staticClass:"group-icon",class:item[_vm.iconUUID]}):_c('i',{staticClass:"group-icon",class:['iconfont', item[_vm.iconUUID] || 'iconyeqian']}),_c('span',{staticClass:"group-title"},[_vm._v(_vm._s(item[_vm.nameUUID]))])]),_vm._l((item[_vm.childrenUUID]),function(child,cIndex){return _c('span',{key:cIndex},[(
								!child[_vm.hideUUID]
								&& _vm.judgingArchiAPermission(child[_vm.archiTypeUUID], child[_vm.authUUID], true)
								&& _vm.judgingCustomLogic(child[_vm.logicsUUID])
							)?_c('el-menu-item',{class:{
								'is-active': _vm.isCurrentActive(child),
								'without-active': !_vm.isCurrentActive(child)
							},attrs:{"index":child[_vm.urlUUID] || child[_vm.gotoUrlUUID]},on:{"click":function($event){return _vm.onItem(child)}}},[_c('template',{slot:"title"},[(child[_vm.iconUUID].toString().indexOf('el-icon') === 0)?_c('i',{staticClass:"item-icon",class:child[_vm.iconUUID]}):_c('i',{staticClass:"item-icon",class:['iconfont', child[_vm.iconUUID] || 'iconyeqian']}),_c('span',[_vm._v(_vm._s(child[_vm.nameUUID]))])])],2):_vm._e()],1)})],2):(
						_vm.judgingArchiAPermission(item[_vm.archiTypeUUID], item[_vm.authUUID], true)
						&& (!item[_vm.hideUUID])
						&& _vm.showLibrary(item[_vm.urlUUID] || item[_vm.gotoUrlUUID])
						&& _vm.judgingCustomLogic(item[_vm.logicsUUID])
					)?_c('el-menu-item',{class:{
					'is-active': _vm.isCurrentActive(item),
					'without-active': !_vm.isCurrentActive(item)
				},attrs:{"index":item[_vm.urlUUID] || item[_vm.gotoUrlUUID]},on:{"click":function($event){return _vm.onItem(item)}}},[(item[_vm.iconUUID].toString().indexOf('el-icon') === 0)?_c('i',{staticClass:"item-icon",class:item[_vm.iconUUID]}):_c('i',{staticClass:"item-icon",class:['iconfont', item[_vm.iconUUID] || 'iconyeqian']}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item[_vm.nameUUID]))])]):_vm._e()}),1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }