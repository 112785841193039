<!--
 * @Description: 分组
 * @Author: luocheng
 * @Date: 2021-11-05 16:49:59
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-09 14:48:42
-->
<template>
  <div class="group-section">
    <h4 class="title">{{ groupData.company_name }}</h4>
    <ArchiTree :treeData="archiData"></ArchiTree>
  </div>
</template>

<script>
import ArchiTree from "./ArchiTree";

export default {
  name: 'GroupSection',
  props: {
    groupData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    ArchiTree
  },
  data() {
    return {
      // 架构数据
      archiData: [
        {
          id: 1,
          label: '筑云集团',
          children: [
            {
              id: 2,
              label: '烽火子集团',
              children: [
                {
                  id: 21,
                  label: '子不语公司'
                },
                {
                  id: 22,
                  label: '公司名称五六七八九五六七八九'
                },
                {
                  id: 23,
                  label: '列表项内容'
                }
              ]
            },
            {
              id: 3,
              label: '辽源子集团',
              children: [
                {
                  id: 31,
                  label: '辽源子公司1'
                },
                {
                  id: 32,
                  label: '辽源子公司2'
                },
								{
                  id: 33,
                  label: '辽源子公司1'
                },
                {
                  id: 34,
                  label: '辽源子公司2'
                },
								{
                  id: 35,
                  label: '辽源子公司1'
                },
                {
                  id: 36,
                  label: '辽源子公司2'
                }
              ]
            },
            {
              id: 4,
              label: '惊梦公司',
              children: []
            }
          ]
        }
      ]
    }
  },
  created() {
    this.archiData = this.groupData ? this.groupData.children : [];
  }
}
</script>

<style lang="less" scoped>
@width: 252px;
.group-section{
  width: @width;
  margin-bottom: 12px;
  height: auto;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  &:last-of-type{
    margin: 0;
  }
  .title {
		box-sizing: border-box;
		padding: 6px 16px;
		width: 100%;
		font-size: 12px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: left;
		color: #445e96;
		line-height: 20px;
	}
}
</style>