<!--
 * @Description: 企业分组
 * @Author: luocheng
 * @Date: 2021-11-05 15:32:30
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-13 17:11:15
-->
<template>
	<div class="group-item" :style="{ ...styleObj}">
		<h4 class="title">{{ title }}</h4>
		<div class="item-content">
			<template v-if="dataList && dataList.length">
				<section
					class="item"
					v-for="item in dataList"
					:key="item.id"
					@click="onItem(item)"
					:class="{
						'is-active': cacheArchi ? cacheArchi.id === item.id : targetArchi && targetArchi.id === item.id
					}"
				>
					<img :src="projectObj[projectEnvironment].little_logo">
					<p class="label">{{ item.short_name || item.name }}</p>
					<i class="iconfont iconselect-duigou" v-if="item.id === current"></i>
				</section>
			</template>
			<el-empty description="暂无数据" v-else :image-size="90"></el-empty>
		</div>
	</div>
</template>

<script>
import { Empty } from 'element-ui';
import { projectObj } from '@/config/projectConfig';
import { mapState } from 'vuex';
export default {
	name: 'GroupItem',
	components: {
		'el-empty': Empty
	},
	props: {
		// 标题
		title: {
			type: String,
			required: true,
			default: ''
		},
		// 样式
		styleObj: {
			type: Object,
			require: false,
			default: () => {}
		},
		// 数据列表
		dataList: {
			type: Array,
			required: true,
			default: () => []
		},
		// 当前选中
		current: {
			type: [String, Number],
			required: false
		}
	},
	data() {
		return {
			projectObj
		};
	},
	computed: {
		...mapState(['cacheArchi', 'targetArchi', 'projectEnvironment'])
	},
	created() {},
	methods: {
		/**
		 * @desc: 点击架构
		 * @param {Object} data
		 */
		onItem(data) {
			console.log(data);
      this.$store.commit('setCacheArchi', data);
		}
	}
};
</script>

<style lang="less" scoped>
@height: 40px;
.group-item {
	height: 100%;
	width: 100%;
	background: #fff;
		position: relative;
	border-radius: 4px;
	.title {
		box-sizing: border-box;
		padding: 10px 16px;
		width: 100%;
		font-size: 12px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: left;
		color: #445e96;
		line-height: 20px;
	}
	.item-content {
		.item {
			display: flex;
			height: @height;
			width: 100%;
			overflow: hidden;
			box-sizing: border-box;
			padding: 10px 16px;
			cursor: pointer;
			&:hover {
				background: #d0e2ff;
			}
			&.is-active {
				background: #d0e2ff;
			}
			img {
				height: 20px;
				width: 20px;
				border-radius: 2px;
				overflow: hidden;
			}
			.label {
				flex: 1;
				box-sizing: border-box;
				padding: 0 8px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #161616;
				line-height: 20px;
			}
			.iconfont {
				font-size: 14px;
				color: #161616;
				line-height: 20px;
			}
		}
		.el-empty {
			padding: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
</style>